<template>
  <div class="text-center">
    Marketplace Offering<br>Coming soon
  </div>
</template>

<script>
export default {

};
</script>

<style>

</style>